import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './scrollView.style.css';

export default class ScrollView extends Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  render() {
    const style = {
      fontSize: "16px",
      // fontSize: "13px",
      paddingLeft: "13px",
      fontWeight: 600,
      // color: '#0a0a0a'  // black
      //color: "#6fb243", // green
      color:"#f00", // red
    };

    const promo = {
      fontSize: '11px',
      fontWeight: 600,
      //color: '#0a0a0a'
      color: '#f11d20'
    };

    const { settings, children, activeIndex, heading } = this.props;
    return (
      <div>
        <div className="scroll-view__header">
          <h1 className="scroll-view__heading">{heading}</h1>

          <div className="scroll-view__controls">
            <span
              className="scroll-view__controls--button"
              style={{ opacity: activeIndex === 0 ? 0.4 : 1 }}
              onClick={this.previous}
            >
              <img src="/img/nav-prev.svg" alt="Previous" />
            </span>
            <span
              className="scroll-view__controls--button"
              style={{ opacity: activeIndex === children.length - 1 ? 0.4 : 1 }}
              onClick={this.next}
            >
              <img src="/img/nav-next.svg" alt="Next" />
            </span>
          </div>
        </div>

        {heading == "Give your trampoline a special name!" && (
          <p style={{ "padding-left": "15px" }}></p>
        )}
        {heading ==
          "This customizer tool is currently under maintenance and some features may not work. Please call our customer line @ 1-877-JUMPSAFE or check back later. Sorry for the inconvenience." && (
          <div>
            {/* <span style={style}>$199 custom fee applied at checkout.</span> */}        
          </div>
        )}
        {heading == "Choose your trampoline model" && (
          <div style={style}>
            <p>$199 customization fee applied at checkout.</p>
          </div>
        )}

        <Slider {...settings} ref={(c) => (this.slider = c)}>
          {children}
        </Slider>

        <div class="color-notified">
          Subject to eligibility. Payment options through Affirm are provided by
          these lending partners:{" "}
          <a target="_blank" href="https://www.affirm.com/lenders">
            affirm.com/lenders
          </a>
        </div>
      </div>
    );
  }
}
